export const NOT_APPLICABLE = "N/A";

export const IMAGE_OPTION = {
  logoWidth : 20,
  logoHeight : 20,
}

export const PAYMENT_OPTIONS = {
  PREPAID: "Prepaid",
  POSTPAID: "Postpaid",
  PAYNOW: "PayNow",
  PAYLATER: "PayLater",
};

export const ORDER_TYPES = {
  ONE_TIME_ORDER: "One Time Order",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  ALTERNATIVE_DAYS: "Alternative Days",
};

export const STATUSES = {
  ACTIVE: "Active",
  PAUSED: "Paused",
};

export const ORDER_STATUSES = {
  CONFIRMED: "Confirmed",
  CANCELLED: "Canceled",
  PENDING: "Pending",
};

export const SUBSCRIPTION_TYPES = {
  SUBSCRIPTION: "Subscription",
  NON_SUBSCRIPTION: "Non Subscription",
};

export const PAYMENT_TYPES = {
  CREDIT: "Credit",
  DEBIT: "Debit",
  REFUND: "Refund",
};

export const CityOptions = [
  "Thiruvananthapuram",
  // "Kochi",
  // "Kozhikode",
  // "Kannur",
  // "Thrissur",
];

export const ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  USER: 3,
  DRIVER: 4
};