import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import {
  Select,
  TextField,
  Typography,
  useTheme,
  MenuItem,
  Snackbar,
  Alert,
  Modal,
  InputLabel,
  FormHelperText,
  Divider,
  FormControl,
  Button,
  Tooltip
} from "@mui/material";
import Box from "@mui/material/Box";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { GET, UPDATE } from "../../Functions/apiFunction";
import api from "../../Data/api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import moment from "moment/moment";

function DeliveryReAssigneRequest() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [reQuestReAssign, setReQuestReAssign] = useState();
  const [allReQuestReAssign, setAllReQuestReAssign] = useState();
  const [pageSize, setpageSize] = useState(20);
  const [alertType, setalertType] = useState("");
  const [alertMsg, setalertMsg] = useState("");
  const [snakbarOpen, setsnakbarOpen] = useState(false);
  const handleSnakBarOpen = () => setsnakbarOpen(true);
  const handleSnakBarClose = () => setsnakbarOpen(false);
  const [isStateUpdated, setUpdatedState] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedExecutive, setselectedExecutive] = useState();
  const [exsistingExecutive, setExsistingExecutive] = useState();
  const [executiveList, setExecutiveList] = useState([]);
  const [assingnExecutiveModelOpen, setAssingnExecutiveModelOOpen] =
    useState(false);
  const [errors, setErrors] = useState({ executive: false });

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    const getStockApprovalData = async () => {
      const url = `${api}/get_all_re_assigne_request_data`;
      const result = await GET(token, url);
      setReQuestReAssign(result.data);
      setAllReQuestReAssign(result.data);
      setUpdatedState(true);
    };
    getStockApprovalData();
  };

  const column = useMemo(
    () => [
      { field: "order_number", headerName: "Order Number#", width: 150 },
      {
        field: "delivery_executive",
        headerName: "Delivery Executive",
        width: 250,
        renderCell: (params) => (
          <Tooltip
            title={
              `${params?.row.executive_number} - ${params?.row.delivery_boy_name}` ||
              "--"
            }
          >
            <span
              style={{
                whiteSpace: "normal",
                overflowWrap: "break-word",
                display: "block",
                maxHeight: "4.5em", // Adjust as needed
                overflow: "hidden",
              }}
            >
              {`${params?.row.executive_number} - ${params?.row.delivery_boy_name}` ||
                "--"}
            </span>
          </Tooltip>
        ),
      },
      {
        field: "assigned_date",
        headerName: "Delivery Date",
        width: 220,
        renderCell: (params) =>
          moment(params.row.assigned_date).local().format("DD-MM-YYYY"),
      },
      {
        field: "request_delivery_executive",
        headerName: "Requested Delivery Executive",
        width: 250,
        renderCell: (params) =>
          params?.row.request_executive_number
            ? `${params?.row.request_executive_number} - ${params?.row.request_executive_name}`
            : "N/A",
      },
      {
        field: "Action",
        headerName: "Action",
        width: 400,
        renderCell: (params) => (
          <div style={{ display: "flex", gap: "10px" }}>
            {params?.row.request_executive_number && (
              <button
                class="AssignButton"
                style={{ backgroundColor: "green" }}
                onClick={() => handleOpen(params.row)}
              >
                {" Assign Requested Executive "}
              </button>
            )}
            <button
              class="AssignButton"
              style={{ backgroundColor: "blue" }}
              onClick={() => handleAssignExecutiveModelOpen(params.row)}
            >
              {" Assign New Executive "}
            </button>
          </div>
        ),
      },
    ],
    [navigate, reQuestReAssign]
  );

  const handleAssignExecutiveModelOpen = async (row) => {
    const url = `${api}/get_delivery_executive_by_order/${row?.order_id}`;
    setErrors({ executive: false });
    setExsistingExecutive(row);
    setUpdatedState(false);
    setSelectedId(row?.id);
    const executiveRes = await GET(token, url);
    if (executiveRes.response === 200) {
      const filteredData = executiveRes.data.filter(
        (a) => a.id !== row?.delivery_executive_id
      );
      setExecutiveList(filteredData);
      setAssingnExecutiveModelOOpen(true);
      setUpdatedState(true);
    } else {
      setUpdatedState(true);
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Something went Wrong! to get the Executives");
    }
  };

  const handleSubmit = async () => {
    if (!selectedExecutive) {
      setErrors({ executive: true });
      return;
    }
    const data = {
      id: selectedId,
      selected_executive: selectedExecutive,
      exsisting_executive: exsistingExecutive,
    };
    const url = `${api}/delivery_re_assign_Order`;
    setUpdatedState(false);
    const update = await UPDATE(token, url, data);
    if (update.response === 200) {
      getAllData();
      handleSnakBarOpen();
      setalertType("success");
      setalertMsg("Order Reassigned succesfully.");
    } else if (update.response === 201) {
      setUpdatedState(true);
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg(update.message);
    } else {
      setUpdatedState(true);
      handleSnakBarOpen();
      setalertType("error");
      setalertMsg("Something went Wrong! Please Try Again");
    }
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setAssingnExecutiveModelOOpen(false);
    setselectedExecutive();
    setSelectedId(null);
    setExsistingExecutive();
    setErrors({ executive: false });
  };

  const handleOpen = (row) => {
    setExsistingExecutive(row);
    setSelectedId(row?.id);
    setselectedExecutive({
      id: row?.reassigned_executive_id,
      executive_id: row?.request_executive_number,
      name: row?.request_executive_name,
    });
    setOpen(true);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ marginBottom: "1rem" }}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <GridToolbarExport color="secondary" sx={{ fontSize: "14px" }} />
          <Select
            sx={{
              width: "100px",
              height: "30px",
            }}
            color="primary"
            size="small"
            labelId="demo-select-small"
            id="demo-select-small"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setpageSize(e.target.value);
            }}
            className="TopPageBar"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <div style={{ height: "100%" }}>
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnakBarClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ height: " 100%", width: "100%" }}>
        <Box className="flex items-center flex-wrap justify-between gap-4 w-100 title-menu">
          <Typography
            className=""
            variant="h2"
            component={"h2"}
            fontWeight={600}
            fontSize={"1.5rem"}
            lineHeight={"2rem"}
            sx={{
              color: theme.palette.mode === "dark" ? "#ffffffe6" : "#0e0e23",
            }}
          >
            Manage Request Reassign
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"1rem"}
            width={"32.33%"}
          >
            <TextField
              size="small"
              sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
              id="Search"
              label="Search"
              name="Search"
              color="secondary"
              onChange={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  function searchArrayByValue(arr, searchQuery) {
                    return arr.filter((obj) => {
                      return Object.values(obj).some((val) => {
                        if (typeof val === "string") {
                          return val
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        if (typeof val === "number") {
                          return val
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        return false;
                      });
                    });
                  }
                  setReQuestReAssign(
                    searchArrayByValue(
                      allReQuestReAssign,
                      e.target.value.toLowerCase()
                    )
                  );
                }, 500);
              }}
            />
          </Box>
        </Box>

        {isStateUpdated ? (
          <Box
            className={`text-card-foreground shadow-sm rounded-lg height-calc p-4 xl:p-2 ${
              theme.palette.mode === "dark" ? "bg-darkcard" : "bg-card"
            }`}
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor:
                  theme.palette.mode === "dark" ? "#334155" : "#0e0e23",
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[0],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#334155" : "#0e0e23",
                color: "#f5f5f5",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={reQuestReAssign}
              components={{ Toolbar: CustomToolbar }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography>
            Are you sure you want to Reassign the order to{" "}
            {exsistingExecutive?.request_executive_number} -{" "}
            {exsistingExecutive?.request_executive_name}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              style={{
                padding: "8px 16px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Confirm
            </Button>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
              style={{
                padding: "8px 16px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={assingnExecutiveModelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            textAlign: "center",
            borderRadius: 3,
          }}
        >
          <Typography
            className=""
            variant="h4"
            component={"h4"}
            fontWeight={600}
            // fontSize={'1rem'}
            lineHeight={"2rem"}
            sx={{
              color: theme.palette.mode === "dark" ? "#ffffffe6" : "#0e0e23",
            }}
          >
            Assign Delivery Executive
          </Typography>
          <Divider style={{ margin: "1rem 0" }} />

          <TextField
            required
            disabled
            fullWidth
            label="Order Number#"
            type="text"
            variant="outlined"
            sx={{ mb: 2 }}
            value={exsistingExecutive?.order_number}
            InputProps={{ inputProps: { min: 1 } }}
          />
          <TextField
            required
            fullWidth
            disabled
            label="Assigned Date"
            type="Date"
            variant="outlined"
            sx={{ mb: 2 }}
            value={exsistingExecutive?.assigned_date}
            InputProps={{ inputProps: { min: 1 } }}
          />
          <FormControl fullWidth sx={{ mb: 2, textAlign: "left" }}>
            <InputLabel id="optional-select-label">Executive</InputLabel>

            <Select
              labelId="optional-select-label"
              value={selectedExecutive}
              label="Executive"
              onChange={(e) => {
                setselectedExecutive(e.target.value);
                setErrors({ executive: false });
              }}
            >
              {executiveList.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {`${item.executive_id} - ${item.name}`}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: "red" }}>
              {errors.executive ? "choose executive" : ""}
            </FormHelperText>
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "0.5rem",
            }}
          >
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              style={{
                width: "100%",
              }}
            >
              Submit
            </Button>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
              style={{
                width: "100%",
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DeliveryReAssigneRequest;
