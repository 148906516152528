import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  useTheme,
  Modal,
  Button,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { margin, Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { GET } from "../../Functions/apiFunction";
import api from "../../Data/api";
import { tokens } from "../../theme";
import { DateRangePicker } from "react-date-range";
import "jspdf-autotable";
import { addDays } from "date-fns";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import Utils from "../../Global/utils";
import logo from "../../assets/a_logo.png";
import * as CONSTANTS from "../../Common/Constants";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90vw",
    sm: "fit-content",
    md: "fit-content",
    lg: "fit-content",
    xl: "fit-content",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
  textAlign: "center",
};

function SalesReport() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [orders, setorders] = useState();
  const [MainOrders, setMainOrders] = useState();
  const [pageSize, setpageSize] = useState(20);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [backdropOpen, setbackdropOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBackDropOpen = () => setbackdropOpen(true);
  const handleBackDropClose = () => setbackdropOpen(false);
  const [isDateRange, setisDateRange] = useState(false);
  const [dataSet, setdataSet] = useState([]);
  const [dateRange, setdateRange] = useState([
    {
      endDate: new Date(),
      startDate: addDays(new Date(), -7),
      key: "selection",
    },
  ]);

  // const date = new Date();
  // const formattedDate = `${String(date.getDate()).padStart(2, "0")}_${String(
  //   date.getMonth() + 1
  // ).padStart(2, "0")}_${date.getFullYear()}`;

  const { toDate, fromDate } = Utils.getDateRange();

  useEffect(() => {
    // Get orders
    const fetchData = async () => {
      const url = `${api}/get_sales_report`;
      const orders = await GET(token, url);
      setorders(orders.data);
      setMainOrders(orders.data);

      setstartDate(fromDate);
      setendDate(toDate);
    };
    fetchData();
  }, [token]);

  const column = useMemo(() => [
    { field: "order_number", headerName: "Order ID", width: 120 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "phone", headerName: "Phone", width: 120 },
    {
      field: "title",
      headerName: "Products",
      width: 220,
      renderCell: (params) => {
        const title =
          params.row.subscription_type !== null
            ? `${params.row.title} (Qty ${params.row.qty})`
            : JSON.parse(params.row.product_detail)
                ?.map(
                  (product) => `${product.product_title} (Qty ${product.qty})`
                )
                .join(", ");

        return (
          <Tooltip title={title} arrow>
            <Typography
              variant="body2"
              noWrap
              style={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "subscription_type",
      headerName: "Subscription Type",
      width: 130,
      renderCell: (params) => {
        let subscriptionText = Utils.getSubscriptionType(
          params.row.subscription_type
        );
        return <p>{subscriptionText === "N/A" ? "Buyonce" : subscriptionText}</p>;
      },
    },
    {
      field: "order_type",
      headerName: "Order Type",
      width: 120,
      renderCell: (params) => {
        let orderText = Utils.getOrderType(params.row.order_type);
        return <p>{orderText}</p>;
      },
    },
    {
      field: "created_at",
      headerName: "Created Date",
      width: 140,
      renderCell: (params) =>
        moment.utc(params.row.created_at).local().format("DD-MM-YYYY"),
    },
    {
      field: "qty",
      headerName: "Qty",
      width: 80,
      renderCell: (params) => {
        let quantity = params.row.qty;
        let subscriptionType = params.row.subscription_type;
        return Utils.getUpdatedTotalQuantity(quantity, subscriptionType);
      },
    },
    {
      field: "order_amount",
      headerName: "Order Amount",
      width: 100,
      renderCell: (params) => {
        const orderAmount = Utils.calculateOrderAmount(
          params.row.order_amount,
          params.row.refund_amount
        );

        // Display "Invalid Order Amount" if the function returned null
        if (orderAmount === null) {
          return <p>Invalid Order Amount</p>;
        }

        const style = { color: orderAmount < 0 ? "red" : "inherit" };

        // Display the calculated amount, formatted to two decimal places
        return <p style={style}>{orderAmount}</p>;
      },
    },
    {
      field: "profit_loss",
      headerName: "Total collected",
      width: 150,
      renderCell: (params) => {
        const { text, color } = Utils.getProfitLossDisplayWithColorCode(
          params.row.order_number,
          params.row.unique_id,
          orders
        );
        return <p style={{ color }}>{text}</p>;
      },
    },
  ]);

  const exportToCSV = () => {
    const dateRange =
      startDate && endDate ? `Date Range: ${startDate} to ${endDate}` : null;

    // Prepare the headers and data
    const headers = [
      "S.No",
      "Order ID",
      "Name",
      "Phone",
      "Products",
      "Subscription Type",
      "Order Type",
      "Created Date",
      "Qty",
      "Order Amount",
      "Total Collected",
    ];

    const reversedOrders = [...orders].reverse();

    const csvData = reversedOrders
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      .map((row, index) => {
        const rowCopy = { ...row };

        // Update the qty property on the copy, not the original row
        rowCopy.qty = Utils.getUpdatedTotalQuantity(
          row.qty,
          row.subscription_type
        );

        const subscriptionType = Utils.getSubscriptionType(row.subscription_type);

        return [
          index + 1,
          row.order_number,
          row.name,
          row.phone,
          row.subscription_type !== null
            ? row.title
            : JSON.parse(row.product_detail)
                ?.map(
                  (product) => `${product.product_title} (Qty ${product.qty})`
                )
                .join(", "),
          subscriptionType === "N/A" ? "Buyonce" : subscriptionType,
          Utils.getOrderType(row.order_type),
          moment.utc(row.created_at).local().format("DD-MM-YYYY"),
          rowCopy.qty,
          Utils.calculateOrderAmount(row.order_amount, row.refund_amount),
          Utils.getProfitLossDisplay(row.order_number, row.unique_id, orders),
        ];
      });

    // Create worksheet and workbook
    const workbook = XLSX.utils.book_new();

    // Prepare the data for the worksheet
    const tempData = [
      ...(startDate && endDate ? [[dateRange], []] : []), // Add date range and empty row if available
      headers,
      ...csvData,
    ];

    // Convert tempData to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(tempData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Report");

    // Set the filename and download
    XLSX.writeFile(workbook, `Sales_Report_${startDate}_${endDate}.csv`);
  };

  const exportToPDF = () => {
    const dateRange = `Date Range: ${startDate} to ${endDate}`;
    // const doc = new jsPDF();

    // Create a jsPDF instance with A3 landscape orientation for more width
    const doc = new jsPDF({
      orientation: "landscape", // Set to landscape to increase width
      unit: "mm", // Measurement unit in millimeters
      format: "a4", // Use A3 size for wider layout
    });

    // Set header font size and center-align it
    doc.setFontSize(18);
    const headerText = "Sales Report";
    const headerX =
      (doc.internal.pageSize.getWidth() - doc.getTextWidth(headerText)) / 2;
    doc.text(headerText, headerX, 20);

    Utils.getBase64FromImage(logo, (base64Logo) => {
      const logoWidth = CONSTANTS.IMAGE_OPTION.logoWidth;
      const logoHeight = CONSTANTS.IMAGE_OPTION.logoHeight;
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.addImage(
        base64Logo,
        "PNG",
        pageWidth - logoWidth - 15,
        10,
        logoWidth,
        logoHeight
      );
      // Set smaller font size for the date range text below header
      doc.setFontSize(12);
      if (startDate && endDate) {
        doc.text(dateRange, 20, 40);
      }

      // Define table headers with column names and configure column width
      const tableColumn = [
        { header: "S.No", dataKey: "sno" },
        { header: "Order ID", dataKey: "order_number" },
        { header: "Name", dataKey: "name" },
        { header: "Phone", dataKey: "phone" },
        { header: "Products", dataKey: "title" },
        { header: "Subscription Type", dataKey: "subscription_type" },
        { header: "Order Type", dataKey: "order_type" },
        { header: "Created Date", dataKey: "created_at" },
        { header: "Qty", dataKey: "qty" },
        { header: "Order Amount", dataKey: "order_amount" },
        { header: "Total Collected", dataKey: "total_collected" },
      ];

      const reversedOrders = [...orders].reverse();

      // Map table rows and format data as needed
      const tableRows = reversedOrders
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .map((row, index) => ({
          sno: index + 1,
          order_number: row.order_number,
          name: row.name,
          phone: row.phone,
          title:
            row.subscription_type !== null
              ? row.title
              : JSON.parse(row.product_detail)
                  ?.map(
                    (product) => `${product.product_title} (Qty ${product.qty})`
                  )
                  .join(", "),
          subscription_type: Utils.getSubscriptionType(row.subscription_type) === "N/A" ? "Buyonce" : Utils.getSubscriptionType(row.subscription_type),
          order_type: Utils.getOrderType(row.order_type),
          created_at: moment.utc(row.created_at).local().format("DD-MM-YYYY"),
          qty: Utils.getUpdatedTotalQuantity(row.qty, row.subscription_type),
          order_amount: Utils.calculateOrderAmount(
            row.order_amount,
            row.refund_amount
          ),
          total_collected: Utils.getProfitLossDisplay(
            row.order_number,
            row.unique_id,
            orders
          ),
        }));

      const tableStartY = 20 + logoHeight + 6;
      doc.addFont(
        "meera-regular-unicode-font-normal.ttf",
        "meera-regular-unicode-font-normal",
        "normal"
      );
      doc.setFont("meera-regular-unicode-font-normal");
      // Add the table with column width adjustment and long text wrapping options
      doc.autoTable({
        head: [tableColumn.map((col) => col.header)],
        body: tableRows.map((row) => Object.values(row)),
        startY: tableStartY,
        margin: { left: 20 },
        halign: "center",
        styles: {
          fontSize: 10, // Adjust font size for table content
          cellWidth: "auto",
        },
        headStyles: {
          fillColor: [0, 162, 51],  // Orange background
          textColor: [255, 255, 255], // White text
          fontSize: 10, // Reduced font size for header
          lineWidth: 0.2, // Set border thickness for header
          halign: "center", // Center-align the table headers
        },
        bodyStyles: {
          font: "meera-regular-unicode-font-normal",
          lineWidth: 0.2, // Set border thickness for body cells
          lineColor: [0, 0, 0], // Black border color
        },
        styles: {
          fontSize: 10, // Adjust font size for table content
          cellPadding: 3, // Add padding to cells for better appearance
          lineWidth: 0.2, // General border thickness
          lineColor: [0, 0, 0], // General border color
        },
        columnStyles: {
          4: { cellWidth: 40 },
          // 10: { cellWidth: 25 },
        },
        showHead: "firstPage",
        didDrawCell: (data) => {
          if (
            data.column.dataKey === "title" &&
            data.cell.text[0].length > 15
          ) {
            doc.setFontSize(8); // Adjust font size for longer text in cells
          }
        },
      });

      // After the table is completely generated, add the page numbers
      const totalPages = doc.internal.getNumberOfPages(); // Get total pages
      doc.setFontSize(9);
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i); // Set the page context to the current page
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const pageText = `Page ${i} of ${totalPages}`; // Format "Page X/Y"
        const marginRight = 15;
        const marginBottom = i === 1 ? 7 : 10;

        // Add page number at the bottom-right of the page
        doc.text(
          pageText,
          pageWidth - marginRight - doc.getTextWidth(pageText),
          pageHeight - marginBottom
        );
      }

      // Save the PDF with a date-based file name
      doc.save(`Sales_Report_${startDate}_${endDate}.pdf`);
    });
  };

  const filter = async (url) => {
    handleBackDropOpen();
    const report = await GET(token, url);
    handleBackDropClose();
    setorders(report.data);
    setMainOrders(report.data);

    let array = getDateValueArray(report.data);
    const data_Set = () => {
      let arr = [];
      for (let i = 0; i < array.length; i++) {
        let data = {
          date: array[i].date,
          value: array[i].values.length,
        };
        arr.push(data);
      }
      setdataSet(arr);
    };

    data_Set();
  };

  function getDateValueArray(inputArray) {
    // Create an object to store the unique dates and their values
    const dateValueObj = inputArray.reduce((acc, curr) => {
      // Get the current date from the input array item
      const currDate = curr.date;

      // If the date is not already in the accumulator object, add it
      if (!acc[currDate]) {
        acc[currDate] = [];
      }

      // Add the current item's value to the date's array in the accumulator object
      acc[currDate].push(1);

      return acc;
    }, {});

    // Convert the accumulator object to an array
    const dateValueArray = Object.entries(dateValueObj).map(
      ([date, values]) => {
        return { date, values };
      }
    );

    return dateValueArray;
  }

  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              label="Select Date Range"
              variant="outlined"
              Autocomplete={false}
              size="small"
              color="secondary"
              onKeyDown={() => {
                return false;
              }}
              onClick={handleOpen}
              value={startDate && `${startDate} - ${endDate}`}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (isDateRange === true) {
                  let url = `${api}/get_sales_report/${startDate}/${endDate}`;
                  filter(url);
                } else {
                  let url = `${api}/get_sales_report`;
                  filter(url);
                }
              }}
              disabled={!startDate && !endDate}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setisDateRange(false);
                setstartDate(fromDate);
                setendDate(toDate);
                let url = `${api}/get_sales_report`;
                filter(url);
                setdateRange([
                  {
                    endDate: new Date(),
                    startDate: addDays(new Date(), -7),
                    key: "selection",
                  },
                ]);
              }}
              disabled={!startDate && !endDate}
            >
              Reset
            </Button>
          </Box>

          <Box sx={{ display: "flex", gap: "15px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={exportToCSV}
              disabled={orders.length === 0}
            >
              Export to CSV
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={exportToPDF}
              disabled={orders.length === 0}
            >
              Export to PDF
            </Button>
          </Box>
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <div style={{ height: "100%" }}>
      <Box sx={{ height: " 100%", width: "100%" }}>
        <Box className="flex items-center flex-wrap justify-between gap-4 w-100 title-menu">
          <Typography
            className=""
            variant="h2"
            component={"h2"}
            fontWeight={600}
            fontSize={"1.5rem"}
            lineHeight={"2rem"}
            sx={{
              color: theme.palette.mode === "dark" ? "#ffffffe6" : "#0e0e23",
            }}
          >
            Sales Report
          </Typography>

          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"1rem"}
            width={"32.33%"}
          >
            <TextField
              size="small"
              sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
              id="Search"
              label="Search"
              name="Search"
              color="secondary"
              onChange={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  function searchArrayByValue(arr, searchQuery) {
                    return arr
                      .map((obj) => ({
                        ...obj,
                        created_at_temp: obj.created_at
                          .split(" ")[0]
                          .split("-")
                          .reverse()
                          .join("-"),
                        exist_created_at: obj.created_at,
                        exist_order_type: obj.order_type,
                        order_type_temp:
                          obj.order_type === 1
                            ? CONSTANTS.PAYMENT_OPTIONS.PREPAID
                            : obj.order_type === 2
                            ? CONSTANTS.PAYMENT_OPTIONS.POSTPAID
                            : obj.order_type === 3
                            ? CONSTANTS.PAYMENT_OPTIONS.PAYNOW
                            : obj.order_type === 4
                            ? CONSTANTS.PAYMENT_OPTIONS.PAYLATER
                            : "",
                      }))
                      .filter((obj) => {
                        return Object.values(obj).some((val) => {
                          if (typeof val === "string") {
                            return val
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase());
                          }
                          if (typeof val === "number") {
                            return val.toString().includes(searchQuery);
                          }
                          return false;
                        });
                      })
                      .map(
                        ({
                          exist_created_at,
                          created_at_temp,
                          exist_order_type,
                          order_type_temp,
                          ...rest
                        }) => ({
                          ...rest,
                          created_at: exist_created_at,
                          order_type: exist_order_type,
                        })
                      );
                  }
                  setorders(
                    searchArrayByValue(MainOrders, e.target.value.toLowerCase())
                  );
                }, 500);
              }}
            />
          </Box>
        </Box>
        {orders ? (
          <Box
            className={`text-card-foreground shadow-sm rounded-lg height-calc p-4 xl:p-2 ${
              theme.palette.mode === "dark" ? "bg-darkcard" : "bg-card"
            }`}
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor:
                  theme.palette.mode === "dark" ? "#334155" : "#0e0e23",
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[0],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#334155" : "#0e0e23",
                color: "#f5f5f5",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={orders}
              components={{ Toolbar: CustomToolbar }}
              localeText={{
                noRowsLabel: "No records found",
              }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
              getRowId={(row) => row.unique_id} // Ensure the unique ID for rows
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DateRangePicker
            onChange={(item) => {
              setisDateRange(true);
              setstartDate(
                moment(item.selection.startDate).format("DD-MM-YYYY")
              );
              setendDate(moment(item.selection.endDate).format("DD-MM-YYYY"));
              setdateRange([item.selection]);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={dateRange}
            direction="vertical"
            scroll={{ enabled: true }}
          />
          <Box mt={5}>
            {" "}
            <Button
              fullWidth
              variant="contained"
              sx={{ height: "30px", fontWeight: "700", color: "fff" }}
              color="primary"
              onClick={() => {
                if (!startDate) {
                  setisDateRange(true);
                  setstartDate(
                    moment(dateRange[0].startDate).format("DD-MM-YYYY")
                  );
                  setendDate(moment(dateRange[0].endDate).format("DD-MM-YYYY"));
                }
                handleClose();
              }}
            >
              Set
            </Button>
          </Box>
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={backdropOpen}
        onClick={handleBackDropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default SalesReport;
