import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment/moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { ADD, DELETE, GET } from "../../Functions/apiFunction";
import api from "../../Data/api";
import "../../Styles/buttons.css";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { CityOptions } from "../../Common/Constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90vw", sm: 500, md: 500, lg: 500, xl: 500 },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
};

function DeliveryRoutes() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [deliveryRoutes, setDeliveryRoutes] = useState();
  const [allDeliveryRoutes, setAllDeliveryRoutes] = useState();
  const [pageSize, setpageSize] = useState(20);
  const [open, setOpen] = useState(false);
  const [dailogOpne, setdailogOpne] = useState(false);
  const [snakbarOpen, setsnakbarOpen] = useState(false);
  const [alertType, setalertType] = useState("");
  const [alertMsg, setalertMsg] = useState("");
  const [reFetch, setreFetch] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDailogOpen = () => setdailogOpne(true);
  const handleDailogClose = () => setdailogOpne(false);
  const handleSnakBarOpen = () => setsnakbarOpen(true);
  const handleSnakBarClose = () => setsnakbarOpen(false);
  const [isUpdating, setisUpdating] = useState(false);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  // update user state
  const [title, setTitle] = useState("");
  const [Id, setId] = useState("");
  const [routeName, setRouteName] = useState("");
  const [pincode, setPincode] = useState("");
  const [cityName, setCityName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [locations, setLocations] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [routeNameError, setRouteNameError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [latitudeError, setLatitudeError] = useState("");
  const [longitudeError, setLongitudeError] = useState("");

  useEffect(() => {
    const getdeliveryRoutes = async () => {
      try {
        const url = `${api}/get_delivery_routes`;
        const deliveryRoutes = await GET(token, url);
        if (deliveryRoutes.response === 200) {
          const transformedRoutes = deliveryRoutes.data.map((route) => {
            return {
              ...route,
              locations_string:
                route?.locations && route?.locations.length > 0
                  ? route?.locations?.join(", ")
                  : "N/A",
              updated_at: moment(route.delivery_executive_route?.updated_at)
                .local()
                .format("DD-MM-YYYY"),
            };
          });
          setDeliveryRoutes(transformedRoutes || []);
          setAllDeliveryRoutes(transformedRoutes || []);
        } else {
          handleSnakBarOpen();
          setalertType("error");
          setalertMsg("Something went wrong");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getdeliveryRoutes();
  }, [reFetch, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const data = {
      route_name: routeName,
      pincode,
      city_name: cityName,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      locations,
      is_active: isActive,
    };
    if (isUpdate) {
      const updatedData = {
        ...data,
        id: Id,
      };
      await UpdateDeliveryRoute(updatedData);
      return;
    }
    await AddDeliveryRoute(data);
  };

  const validateForm = () => {
    // Reset errors
    resetError();
    // Check if route name already exists (excluding current route if it's an update)
    const routeNameExists = deliveryRoutes.some(
      (route) =>
        route.route_name.toString() === routeName.toString() &&
        (Id === null || Id === undefined || route.id !== Id)
    );
    if (routeNameExists) {
      setRouteNameError("Route Name already exists");
      return false;
    }

    // Check if pincode is at least 6 digits long
    if (!/^\d{6,}$/.test(pincode)) {
      setPincodeError("Pincode must be at least 6 digits");
      return false;
    }

    // Check if pincode already exists (excluding current route if it's an update)
    const pincodeExists = deliveryRoutes?.some(
      (route) =>
        route.pincode.toString() === pincode.toString() &&
        (Id === null || Id === undefined || route.id !== Id)
    );
    if (pincodeExists) {
      setPincodeError("Pincode already exists");
      return false;
    }

    if (
      latitude &&
      (!/^-?\d+(\.\d+)?$/.test(latitude) || latitude < -90 || latitude > 90)
    ) {
      setLatitudeError("Latitude must be a decimal");
      return false;
    }

    // Validate longitude (must be a valid decimal between -180 and 180)
    if (
      longitude &&
      (!/^-?\d+(\.\d+)?$/.test(longitude) ||
        longitude < -180 ||
        longitude > 180)
    ) {
      setLongitudeError("Longitude must be a decimal");
      return false;
    }
    return true;
  };

  const AddDeliveryRoute = async (data) => {
    const url = `${api}/add_delivery_route`;
    setisUpdating(true);
    const addDeliveryRoute = await ADD(token, url, data);
    if (addDeliveryRoute.response === 200) {
      setisUpdating(false);
      handleSnakBarOpen();
      handleClose();
      setreFetch(!reFetch);
      setisUpdating(false);
      setalertType("success");
      setalertMsg("New Delivery Route added successfully");
    } else if (addDeliveryRoute.response === 201) {
      setisUpdating(false);
      handleSnakBarOpen();
      setisUpdating(false);
      setalertType("error");
      setalertMsg(addDeliveryRoute.message);
    } else {
      setisUpdating(false);
      handleSnakBarOpen();
      setisUpdating(false);
      setalertType("error");
      setalertMsg(addDeliveryRoute.response.data.message);
    }
  };

  const UpdateDeliveryRoute = async (data) => {
    const url = `${api}/update_delivery_route`;
    setisUpdating(true);
    const updateDeliveryRoute = await ADD(token, url, data);
    if (updateDeliveryRoute.response === 200) {
      setisUpdating(false);
      handleSnakBarOpen();
      handleClose();
      setreFetch(!reFetch);
      setisUpdating(false);
      setIsUpdate(false);
      setalertType("success");
      setalertMsg("Delivery Route Updated successfully");
    } else if (updateDeliveryRoute.response === 201) {
      setisUpdating(false);
      handleSnakBarOpen();
      setisUpdating(false);
      setalertType("error");
      setalertMsg(updateDeliveryRoute.message);
    } else {
      setisUpdating(false);
      handleSnakBarOpen();
      setisUpdating(false);
      setalertType("error");
      setalertMsg(updateDeliveryRoute.response.data.message);
    }
  };

  // delete
  const deleteCat = async (e) => {
    e.preventDefault();
    var deleteData = JSON.stringify({
      id: Id,
    });
    const url = `${api}/delete_delivery_route`;
    setisUpdating(true);
    const deleteSub = await DELETE(token, url, deleteData);
    setisUpdating(false);
    console.log(deleteSub);

    if (deleteSub.response === 200) {
      handleDailogClose();
      handleClose();
      handleSnakBarOpen();
      resetState();
      setalertType("success");
      setalertMsg("Successfully Deleted");
      setreFetch(!reFetch);
    } else {
      handleDailogClose();
      handleSnakBarOpen();
      setisUpdating(false);
      setalertType("error");
      setalertMsg("Something went Wrong! Please Try Again");
    }
  };

  const resetError = () => {
    setRouteNameError("");
    setPincodeError("");
    setLatitudeError("");
    setLongitudeError("");
  };
  const column = useMemo(
    () => [
      // { field: "id", headerName: "Id", width: 60 },
      { field: "route_name", headerName: "Route Name", width: 180 },
      { field: "pincode", headerName: "Pincode", width: 120 },
      { field: "city_name", headerName: "City", width: 180 },
      {
        field: "locations_string",
        headerName: "Locations",
        width: 220,
      },
      {
        field: "latitude",
        headerName: "Latitude",
        width: 120,
        renderCell: (params) => {
          // Check if latitude is null, display a placeholder or default value
          return params.row?.latitude !== null ? params.row?.latitude : "N/A";
        },
      },
      {
        field: "longitude",
        headerName: "Longitude",
        width: 120,
        renderCell: (params) => {
          // Check if longitude is null, display a placeholder or default value
          return params.row?.longitude !== null ? params.row?.longitude : "N/A";
        },
      },
      {
        field: "is_active",
        headerName: "Status",
        width: 120,
        renderCell: (params) => {
          const isActive = params.row?.is_active;
          return (
            <span
              style={{
                color: isActive ? "green" : "red", // Green for Active, Red for Inactive
                fontWeight: "bold", // Optional, to make the text stand out
              }}
            >
              {isActive ? "Active" : "Inactive"}
            </span>
          );
        },
      },
      {
        field: "updated_at",
        headerName: "Last Updated",
        width: 220,
      },
      {
        field: "Actions",
        headerName: "Actions",
        width: 150,
        renderCell: (params) => (
          <div style={{ display: "flex", gap: "10px" }}>
            {/* Edit Button */}
            <button
              className="updateBtn"
              onClick={() => {
                setId(params.row.id);
                setRouteName(params.row.route_name);
                setPincode(params.row.pincode);
                setCityName(params.row.city_name);
                setLocations(params.row.locations);
                setLatitude(params.row.latitude);
                setLongitude(params.row.longitude);
                setIsActive(params.row.is_active);
                setIsUpdate(true);
                resetError();
                handleOpen();
              }}
            >
              <span className="icon">
                <i className="fa-regular fa-pen-to-square"></i>
              </span>
            </button>

            {/* Delete Button */}
            {/* <button
              className="dltBtn"
              onClick={() => {
                setId(params.row.id);
                setTitle(params.row.route_name);
                handleDailogOpen(); // Handle delete if needed
              }}
            >
              <span className="icon">
                <i className="fa-solid fa-trash"></i>
              </span>
            </button> */}
          </div>
        ),
      },

      // {
      //   field: "Edit",
      //   headerName: "Edit",
      //   width: 100,
      //   renderCell: (params) => (
      //     <button
      //       className="updateBtn"
      //       onClick={() => {
      //         setId(params.row.id);
      //         setRouteName(params.row.route_name);
      //         setPincode(params.row.pincode);
      //         setCityName(params.row.city_name);
      //         setLocations(params.row.locations);
      //         setLatitude(params.row.latitude);
      //         setLongitude(params.row.longitude);
      //         setIsActive(params.row.is_active);
      //         setIsUpdate(true);
      //         handleOpen();
      //       }}
      //     >
      //       <span className="icon">
      //         <i className="fa-regular fa-pen-to-square"></i>
      //       </span>
      //     </button>
      //   ),
      // },
      // {
      //   field: "Delete",
      //   headerName: "Delete",
      //   width: 100,
      //   renderCell: (params) => (
      //     <button
      //       className="dltBtn"
      //       onClick={() => {
      //         setId(params.row.id);
      //         setTitle(params.row.route_name);
      //         handleDailogOpen(); // Handle delete if needed
      //       }}
      //     >
      //       <span className="icon">
      //         <i className="fa-solid fa-trash"></i>
      //       </span>
      //     </button>
      //   ),
      // },
    ],
    []
  );

  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ marginBottom: "1rem" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <GridToolbarExport color="secondary" sx={{ fontSize: "15px" }} />
          <Select
            sx={{
              width: "100px",
              height: "30px",
            }}
            color="primary"
            size="small"
            labelId="demo-select-small"
            id="demo-select-small"
            value={pageSize}
            label="Page Size"
            onChange={(e) => {
              setpageSize(e.target.value);
            }}
            className="TopPageBar"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>

        <button
          class="cssbuttons-io-button"
          onClick={() => {
            resetState();
            handleOpen();
          }}
        >
          {" "}
          Add New
          <div class="icon">
            <i class="fa-regular fa-plus"></i>
          </div>
        </button>
      </GridToolbarContainer>
    );
  }

  const resetState = () => {
    setTitle("");
    setRouteName("");
    setPincode("");
    setCityName("");
    setLocations([]);
    setLatitude();
    setLongitude();
    setIsActive(true);
    setIsUpdate(false);
  };
  return (
    <div style={{ height: "100%" }}>
      <Snackbar
        open={snakbarOpen}
        autoHideDuration={3000}
        onClose={handleSnakBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnakBarClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ height: " 100%", width: "100%" }}>
        <Box className="flex items-center flex-wrap justify-between gap-4 w-100 title-menu">
          <Typography
            className=""
            variant="h2"
            component={"h2"}
            fontWeight={600}
            fontSize={"1.5rem"}
            lineHeight={"2rem"}
            sx={{
              color: theme.palette.mode === "dark" ? "#ffffffe6" : "#0e0e23",
            }}
          >
            Manage Delivery Routes
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"1rem"}
            width={"32.33%"}
          >
            <TextField
              size="small"
              sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
              id="Search"
              label="Search"
              name="Search"
              color="secondary"
              onChange={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  function searchArrayByValue(arr, searchQuery) {
                    return arr.filter((obj) => {
                      return Object.values(obj).some((val) => {
                        if (typeof val === "string") {
                          return val
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        if (typeof val === "number") {
                          return val
                            .toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase());
                        }
                        return false;
                      });
                    });
                  }
                  setDeliveryRoutes(
                    searchArrayByValue(
                      allDeliveryRoutes,
                      e.target.value.toLowerCase()
                    )
                  );
                }, 500);
              }}
            />
          </Box>
        </Box>

        {deliveryRoutes ? (
          <Box
            className={`text-card-foreground shadow-sm rounded-lg height-calc p-4 xl:p-2 ${
              theme.palette.mode === "dark" ? "bg-darkcard" : "bg-card"
            }`}
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor:
                  theme.palette.mode === "dark" ? "#334155" : "#0e0e23",
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[0],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#334155" : "#0e0e23",
                color: "#f5f5f5",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{
                fontSize: "13px",
                "& .MuiDataGrid-row": {
                  maxHeight: "150px !important",
                },
                "& .MuiDataGrid-cell": {
                  maxHeight: "150px !important",
                  whiteSpace: "break-spaces !important",
                },
              }}
              columns={column}
              rows={deliveryRoutes}
              components={{ Toolbar: CustomToolbar }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
              localeText={{
                noRowsLabel: "No records found",
              }}
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isUpdate ? "Update Delivery Route" : "Add New Delivery Route"}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            {/* Route Name */}
            <TextField
              fullWidth
              id="routeName"
              label="Route Name"
              name="routeName"
              required={true}
              value={routeName}
              size="small"
              color="secondary"
              onChange={(e) => {
                setRouteName(e.target.value);
                setRouteNameError("");
              }}
              sx={{ mb: 2 }}
              error={!!routeNameError}
              helperText={routeNameError}
            />

            {/* Pincode */}
            <TextField
              fullWidth
              id="pincode"
              label="Pincode"
              name="pincode"
              type="number"
              required={true}
              value={pincode}
              size="small"
              color="secondary"
              onKeyPress={(e) => {
                // Allow only numbers
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                // Limit input to 6 digits
                if (e.target.value.length <= 6) {
                  setPincode(e.target.value);
                  setRouteNameError("");
                }
              }}
              inputProps={{ maxLength: 6 }}
              sx={{ mb: 2 }}
              error={!!pincodeError}
              helperText={pincodeError}
            />

            {/* City Name */}
            <Autocomplete
              disablePortal
              fullWidth
              id="CityName"
              color="secondary"
              options={CityOptions}
              value={cityName || ""}
              // inputValue={cityName}
              // onInputChange={(event, newInputValue) =>
              //   setCityName(newInputValue)
              // } // To capture the user-typed value
              onChange={(event, newValue) => setCityName(newValue)} // To capture the selected value
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City Name"
                  name="CityName"
                  size="small"
                  fullWidth
                  required
                  color="secondary"
                />
              )}
              sx={{ mb: 2 }}
            />

            {/* Locations (array input) */}
            <TextField
              fullWidth
              id="locations"
              label="Locations (comma-separated)"
              name="locations"
              value={locations.join(", ")} // Display as comma-separated
              size="small"
              color="secondary"
              onChange={(e) => setLocations([e.target.value])} // Allow free typing, including commas
              onBlur={(e) => {
                setLocations(
                  e.target.value
                    .split(/\s*,\s*/g) // Split on commas with optional spaces
                    .filter((loc) => loc.length > 0) // Remove empty values
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setLocations(
                    e.target.value
                      .split(/\s*,\s*/g) // Split when Enter is pressed
                      .filter((loc) => loc.length > 0)
                  );
                  e.preventDefault(); // Prevent form submission
                }
              }}
              sx={{ mb: 2 }}
            />

            {/* Latitude */}
            <TextField
              fullWidth
              id="latitude"
              label="Latitude"
              name="latitude"
              type="number"
              value={latitude}
              size="small"
              color="secondary"
              onChange={(e) => {
                setLatitude(e.target.value);
              }}
              sx={{ mb: 2 }}
              error={!!latitudeError}
              helperText={latitudeError}
            />

            {/* Longitude */}
            <TextField
              fullWidth
              id="longitude"
              label="Longitude"
              name="longitude"
              type="number"
              value={longitude}
              size="small"
              color="secondary"
              onChange={(e) => {
                setLongitude(e.target.value);
              }}
              sx={{ mb: 2 }}
              error={!!longitudeError}
              helperText={longitudeError}
            />

            {/* is_active Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  color="secondary"
                />
              }
              label="Is Active"
              sx={{ mb: 2 }}
            />
            {/* Submit Button */}
            <button className="AddBtn" type="submit" disabled={isUpdating}>
              {isUpdating ? (
                <CircularProgress color="inherit" />
              ) : isUpdate ? (
                "Update Delivery Route"
              ) : (
                "Add New Delivery Route"
              )}
            </button>
          </Box>
        </Box>
      </Modal>

      {/* Dailog */}
      <Dialog
        open={dailogOpne}
        onClose={handleDailogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Delete Delivery Route</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Do you want to delete{" "}
              <b>
                <span>{title}</span>
              </b>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDailogClose} color="secondary">
            Cancel
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={deleteCat}
            autoFocus
            color="error"
          >
            {isUpdating ? <CircularProgress /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeliveryRoutes;
